const PERMISSIONS_LIST = [
  'browse_admin',
  'browse_bread',
  'browse_database',
  'browse_media',
  'browse_compass',
  'browse_users',
  'read_users',
  'edit_users',
  'add_users',
  'delete_users',
  'browse_menus',
  'read_menus',
  'edit_menus',
  'add_menus',
  'delete_menus',
  'browse_roles',
  'read_roles',
  'edit_roles',
  'add_roles',
  'delete_roles',
  'browse_settings',
  'read_settings',
  'edit_settings',
  'add_settings',
  'delete_settings',
  'browse_localidades',
  'read_localidades',
  'edit_localidades',
  'add_localidades',
  'delete_localidades',
  'browse_provincias',
  'read_provincias',
  'edit_provincias',
  'add_provincias',
  'delete_provincias',
  'browse_paises',
  'read_paises',
  'edit_paises',
  'add_paises',
  'delete_paises',
  'browse_puertos',
  'read_puertos',
  'edit_puertos',
  'add_puertos',
  'delete_puertos',
  'browse_lotes',
  'read_lotes',
  'edit_lotes',
  'add_lotes',
  'delete_lotes',
  'browse_barcos',
  'read_barcos',
  'edit_barcos',
  'add_barcos',
  'delete_barcos',
  'browse_viajes',
  'read_viajes',
  'edit_viajes',
  'add_viajes',
  'delete_viajes',
  'browse_transporte',
  'read_transporte',
  'edit_transporte',
  'add_transporte',
  'delete_transporte',
  'browse_navieras',
  'read_navieras',
  'edit_navieras',
  'add_navieras',
  'delete_navieras',
  'browse_agentes_maritimos',
  'read_agentes_maritimos',
  'edit_agentes_maritimos',
  'add_agentes_maritimos',
  'delete_agentes_maritimos',
  'browse_categorias_facturacion',
  'read_categorias_facturacion',
  'edit_categorias_facturacion',
  'add_categorias_facturacion',
  'delete_categorias_facturacion',
  'browse_gastos_incluidos',
  'read_gastos_incluidos',
  'edit_gastos_incluidos',
  'add_gastos_incluidos',
  'delete_gastos_incluidos',
  'browse_modalidad_fletamiento',
  'read_modalidad_fletamiento',
  'edit_modalidad_fletamiento',
  'add_modalidad_fletamiento',
  'delete_modalidad_fletamiento',
  'browse_modos_explotacion',
  'read_modos_explotacion',
  'edit_modos_explotacion',
  'add_modos_explotacion',
  'delete_modos_explotacion',
  'browse_presentacion',
  'read_presentacion',
  'edit_presentacion',
  'add_presentacion',
  'delete_presentacion',
  'browse_productos',
  'read_productos',
  'edit_productos',
  'add_productos',
  'delete_productos',
  'browse_sitios_atraque',
  'read_sitios_atraque',
  'edit_sitios_atraque',
  'add_sitios_atraque',
  'delete_sitios_atraque',
  'browse_subcategoria_facturacion',
  'read_subcategoria_facturacion',
  'edit_subcategoria_facturacion',
  'add_subcategoria_facturacion',
  'delete_subcategoria_facturacion',
  'browse_terminales',
  'read_terminales',
  'edit_terminales',
  'add_terminales',
  'delete_terminales',
  'browse_tipo_buque',
  'read_tipo_buque',
  'edit_tipo_buque',
  'add_tipo_buque',
  'delete_tipo_buque',
  'browse_tipo_contenedor',
  'read_tipo_contenedor',
  'edit_tipo_contenedor',
  'add_tipo_contenedor',
  'delete_tipo_contenedor',
  'browse_tipo_navegacion',
  'read_tipo_navegacion',
  'edit_tipo_navegacion',
  'add_tipo_navegacion',
  'delete_tipo_navegacion',
  'browse_facturado_viaje',
  'read_facturado_viaje',
  'edit_facturado_viaje',
  'add_facturado_viaje',
  'delete_facturado_viaje',
  'browse_facturacion',
  'read_facturacion',
  'edit_facturacion',
  'add_facturacion',
  'delete_facturacion',
  'browse_logistica',
  'read_logistica',
  'edit_logistica',
  'add_logistica',
  'delete_logistica',
  'browse_tipos_operaciones',
  'read_tipos_operaciones',
  'edit_tipos_operaciones',
  'add_tipos_operaciones',
  'delete_tipos_operaciones',
  'browse_operaciones_viaje',
  'read_operaciones_viaje',
  'edit_operaciones_viaje',
  'add_operaciones_viaje',
  'delete_operaciones_viaje',
  'browse_tipos_operaciones_viajes',
  'read_tipos_operaciones_viajes',
  'edit_tipos_operaciones_viajes',
  'add_tipos_operaciones_viajes',
  'delete_tipos_operaciones_viajes',
  'browse_balances',
  'read_balances',
  'edit_balances',
  'add_balances',
  'delete_balances',
  'browse_categorias_gasto',
  'read_categorias_gasto',
  'edit_categorias_gasto',
  'add_categorias_gasto',
  'delete_categorias_gasto',
  'browse_subcategorias_gasto',
  'read_subcategorias_gasto',
  'edit_subcategorias_gasto',
  'add_subcategorias_gasto',
  'delete_subcategorias_gasto',
  'browse_areas_gasto',
  'read_areas_gasto',
  'edit_areas_gasto',
  'add_areas_gasto',
  'delete_areas_gasto',
  'browse_categorias_ingreso',
  'read_categorias_ingreso',
  'edit_categorias_ingreso',
  'add_categorias_ingreso',
  'delete_categorias_ingreso',
  'browse_subcategorias_ingreso',
  'read_subcategorias_ingreso',
  'edit_subcategorias_ingreso',
  'add_subcategorias_ingreso',
  'delete_subcategorias_ingreso',
  'browse_monedas',
  'read_monedas',
  'edit_monedas',
  'add_monedas',
  'delete_monedas',
  'browse_tipo_cuenta_bce',
  'read_tipo_cuenta_bce',
  'edit_tipo_cuenta_bce',
  'add_tipo_cuenta_bce',
  'delete_tipo_cuenta_bce',
  'browse_tipo_cajas_banco',
  'read_tipo_cajas_banco',
  'edit_tipo_cajas_banco',
  'add_tipo_cajas_banco',
  'delete_tipo_cajas_banco',
  'browse_tipo_cuenta_moneda_extranjera',
  'read_tipo_cuenta_moneda_extranjera',
  'edit_tipo_cuenta_moneda_extranjera',
  'add_tipo_cuenta_moneda_extranjera',
  'delete_tipo_cuenta_moneda_extranjera',
  'browse_tipo_inversiones',
  'read_tipo_inversiones',
  'edit_tipo_inversiones',
  'add_tipo_inversiones',
  'delete_tipo_inversiones',
  'browse_categorias_bienes_uso',
  'read_categorias_bienes_uso',
  'edit_categorias_bienes_uso',
  'add_categorias_bienes_uso',
  'delete_categorias_bienes_uso',
  'browse_subcategorias_bienes_uso',
  'read_subcategorias_bienes_uso',
  'edit_subcategorias_bienes_uso',
  'add_subcategorias_bienes_uso',
  'delete_subcategorias_bienes_uso',
  'browse_tipos_resultados_financieros',
  'read_tipos_resultados_financieros',
  'edit_tipos_resultados_financieros',
  'add_tipos_resultados_financieros',
  'delete_tipos_resultados_financieros',
  'browse_tipos_intangibles',
  'read_tipos_intangibles',
  'edit_tipos_intangibles',
  'add_tipos_intangibles',
  'delete_tipos_intangibles',
  'browse_bienes_de_uso_balance',
  'read_bienes_de_uso_balance',
  'edit_bienes_de_uso_balance',
  'add_bienes_de_uso_balance',
  'delete_bienes_de_uso_balance',
  'browse_cajas_bancos_balance',
  'read_cajas_bancos_balance',
  'edit_cajas_bancos_balance',
  'add_cajas_bancos_balance',
  'delete_cajas_bancos_balance',
  'browse_cuentas_bce_balance',
  'read_cuentas_bce_balance',
  'edit_cuentas_bce_balance',
  'add_cuentas_bce_balance',
  'delete_cuentas_bce_balance',
  'browse_gastos_balance',
  'read_gastos_balance',
  'edit_gastos_balance',
  'add_gastos_balance',
  'delete_gastos_balance',
  'browse_ingresos_balance',
  'read_ingresos_balance',
  'edit_ingresos_balance',
  'add_ingresos_balance',
  'delete_ingresos_balance',
  'browse_intangibles_balance',
  'read_intangibles_balance',
  'edit_intangibles_balance',
  'add_intangibles_balance',
  'delete_intangibles_balance',
  'browse_inversiones_balance',
  'read_inversiones_balance',
  'edit_inversiones_balance',
  'add_inversiones_balance',
  'delete_inversiones_balance',
  'browse_monedas_extranjeras_balance',
  'read_monedas_extranjeras_balance',
  'edit_monedas_extranjeras_balance',
  'add_monedas_extranjeras_balance',
  'delete_monedas_extranjeras_balance',
  'browse_resultados_financieros_balance',
  'read_resultados_financieros_balance',
  'edit_resultados_financieros_balance',
  'add_resultados_financieros_balance',
  'delete_resultados_financieros_balance',
  'browse_presupuestos',
  'read_presupuestos',
  'edit_presupuestos',
  'add_presupuestos',
  'delete_presupuestos',
  'browse_estados_presupuestos',
  'read_estados_presupuestos',
  'edit_estados_presupuestos',
  'add_estados_presupuestos',
  'delete_estados_presupuestos',
  'browse_variables_presupuestos',
  'read_variables_presupuestos',
  'edit_variables_presupuestos',
  'add_variables_presupuestos',
  'delete_variables_presupuestos',
  'browse_categorias_gasto_presupuesto',
  'read_categorias_gasto_presupuesto',
  'edit_categorias_gasto_presupuesto',
  'add_categorias_gasto_presupuesto',
  'delete_categorias_gasto_presupuesto',
  'browse_categorias_ingreso_presupuesto',
  'read_categorias_ingreso_presupuesto',
  'edit_categorias_ingreso_presupuesto',
  'add_categorias_ingreso_presupuesto',
  'delete_categorias_ingreso_presupuesto',
  'browse_subcategorias_gasto_presupuesto',
  'read_subcategorias_gasto_presupuesto',
  'edit_subcategorias_gasto_presupuesto',
  'add_subcategorias_gasto_presupuesto',
  'delete_subcategorias_gasto_presupuesto',
  'browse_subcategorias_ingreso_presupuesto',
  'read_subcategorias_ingreso_presupuesto',
  'edit_subcategorias_ingreso_presupuesto',
  'add_subcategorias_ingreso_presupuesto',
  'delete_subcategorias_ingreso_presupuesto',
  'browse_macros_presupuesto',
  'read_macros_presupuesto',
  'edit_macros_presupuesto',
  'add_macros_presupuesto',
  'delete_macros_presupuesto',
  'browse_gastos_presupuesto',
  'read_gastos_presupuesto',
  'edit_gastos_presupuesto',
  'add_gastos_presupuesto',
  'delete_gastos_presupuesto',
  'browse_ingresos_presupuesto',
  'read_ingresos_presupuesto',
  'edit_ingresos_presupuesto',
  'add_ingresos_presupuesto',
  'delete_ingresos_presupuesto',
  'browse_saldos',
  'read_saldos',
  'edit_saldos',
  'add_saldos',
  'delete_saldos',
  'browse_estados_saldos',
  'read_estados_saldos',
  'edit_estados_saldos',
  'add_estados_saldos',
  'delete_estados_saldos',
  'browse_gastos_saldo',
  'read_gastos_saldo',
  'edit_gastos_saldo',
  'add_gastos_saldo',
  'delete_gastos_saldo',
  'browse_ingresos_saldo',
  'read_ingresos_saldo',
  'edit_ingresos_saldo',
  'add_ingresos_saldo',
  'delete_ingresos_saldo',
  'import_lotes',
  'validate_lotes',
  'audit_lotes',
  'reopen_lotes',
  'audit_barcos',
  'validate_barcos',
  'audit_puertos',
  'audit_viajes',
  'observe_viajes',
  'import_balances',
  'validate_balances',
  'audit_balances',
  'reopen_balances',
  'import_presupuestos',
  'validate_presupuestos',
  'audit_presupuestos',
  'reopen_presupuestos',
  'import_saldos',
  'validate_saldos',
  'audit_saldos',
  'reopen_saldos'
] as const;

type ToObj<K extends string> = {
  [P in K]: P;
};

export type PERMISSIONS_KEYS = (typeof PERMISSIONS_LIST)[number];

export type PERMISSIONS_ENUM = ToObj<PERMISSIONS_KEYS>;

export const PERMISSIONS = PERMISSIONS_LIST.reduce((acc, elem) => {
  return {
    ...acc,
    [elem]: elem
  };
}, {} as Record<PERMISSIONS_KEYS, PERMISSIONS_KEYS>);
