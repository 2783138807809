// Service
import PresupuestosService from '../../../services/presupuesto/presupuesto.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { PresupuestoDetalle } from '../../../interfaces/presupuesto/presupuesto';

const initialState: {
  error: string;
  loading: boolean;
  presupuesto: PresupuestoDetalle | null;
} = {
  error: '',
  presupuesto: null,
  loading: true
};

export const fetchPresupuesto = createAsyncThunk<PresupuestoDetalle, number>(
  'presupuesto/fetchPresupuestoStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await PresupuestosService.getPresupuesto(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const presupuestoDetalleSlice = createSlice({
  name: 'presupuestoDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },

    reset(state) {
      state.error = '';
      state.presupuesto = null;
      state.loading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPresupuesto.fulfilled, (state, action) => {
      state.presupuesto = action.payload;
      state.loading = false;
      state.error = '';
    });

    builder.addCase(fetchPresupuesto.pending, (state, action) => {
      if (!state.presupuesto || state.presupuesto.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchPresupuesto.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });
  }
});

export const presupuestoDetalleActions = {
  fetchPresupuesto,
  ...presupuestoDetalleSlice.actions
};

export default presupuestoDetalleSlice.reducer;
