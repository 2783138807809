// Service
import SaldosService from '../../../services/saldo/saldo.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { SaldoDetalle } from '../../../interfaces/saldo/saldo';

const initialState: {
  error: string;
  loading: boolean;
  saldo: SaldoDetalle | null;
} = {
  error: '',
  saldo: null,
  loading: true
};

export const fetchSaldo = createAsyncThunk<SaldoDetalle, number>(
  'saldo/fetchSaldoStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await SaldosService.getSaldo(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const saldoDetalleSlice = createSlice({
  name: 'saldoDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },

    reset(state) {
      state.error = '';
      state.saldo = null;
      state.loading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSaldo.fulfilled, (state, action) => {
      state.saldo = action.payload;
      state.loading = false;
      state.error = '';
    });

    builder.addCase(fetchSaldo.pending, (state, action) => {
      if (!state.saldo || state.saldo.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchSaldo.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });
  }
});

export const saldoDetalleActions = {
  fetchSaldo,
  ...saldoDetalleSlice.actions
};

export default saldoDetalleSlice.reducer;
