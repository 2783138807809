// Service
import BalancesService from '../../../services/balance/balance.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { BalanceDetalle } from '../../../interfaces/balance/balance';

const initialState: {
  error: string;
  loading: boolean;
  balance: BalanceDetalle | null;
} = {
  error: '',
  balance: null,
  loading: true
};

export const fetchBalance = createAsyncThunk<BalanceDetalle, number>(
  'balance/fetchBalanceStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await BalancesService.getBalance(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const balanceDetalleSlice = createSlice({
  name: 'balanceDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },

    reset(state) {
      state.error = '';
      state.balance = null;
      state.loading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.balance = action.payload;
      state.loading = false;
      state.error = '';
    });

    builder.addCase(fetchBalance.pending, (state, action) => {
      if (!state.balance || state.balance.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchBalance.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });
  }
});

export const balanceDetalleActions = {
  fetchBalance,
  ...balanceDetalleSlice.actions
};

export default balanceDetalleSlice.reducer;
