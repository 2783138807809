import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';
import CategoriasGastoService from '../../../../services/balance/parametros/categoriasGastos.service';
import CategoriasGastoPresupuestoService from '../../../../services/presupuesto/parametros/categoriasGastosPresupuesto.service';

// Interfaces
import type { RootState } from '../../../store';
import type {
  CategoriaGastoPresupuesto,
  SubcategoriaGastoPresupuesto
} from '../../../../interfaces/presupuesto/parametros/categoriasGastosPresupuesto';
import { AreaGasto } from '../../../../interfaces/balance/parametros/categoriasGastos';

type Entity = Awaited<
  ReturnType<typeof CategoriasGastoPresupuestoService.getSubcategoriasGastosPresupuesto>
>[number];

export const fetchSubcategoriasGastoPresupuesto = createAsyncThunk<Entity[]>(
  'subcategoriaGastosPresupuesto/fetchSubcategoriasGastoPresupuesto',
  (_params, api) => {
    const { subcategoriaGastosPresupuesto } = api.getState() as RootState;
    if (subcategoriaGastosPresupuesto.entities.length)
      return Promise.resolve(subcategoriaGastosPresupuesto.entities);
    return CategoriasGastoPresupuestoService.getSubcategoriasGastosPresupuesto();
  }
);

export const fetchAreasGastoPresupuesto = createAsyncThunk<AreaGasto[]>(
  'subcategoriaGastosPresupuesto/fetchAreasGastoPresupuesto',
  (_params, api) => {
    const { subcategoriaGastosPresupuesto } = api.getState() as RootState;
    if (subcategoriaGastosPresupuesto.areas.length)
      return Promise.resolve(subcategoriaGastosPresupuesto.areas);
    return CategoriasGastoService.getAreasGastos();
  }
);

interface SubcategoriasGastoPresupuestoState {
  loading: boolean;
  entities: Entity[];
  areas: AreaGasto[];
}

const initialState = {
  areas: [],
  entities: [],
  loading: true
} as SubcategoriasGastoPresupuestoState;

const subcategoriasGastoPresupuestoSlice = createSlice({
  name: 'subcategoriaGastosPresupuesto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubcategoriasGastoPresupuesto.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSubcategoriasGastoPresupuesto.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubcategoriasGastoPresupuesto.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAreasGastoPresupuesto.fulfilled, (state, action) => {
      state.areas = action.payload;
    });
    builder.addCase(fetchAreasGastoPresupuesto.pending, (state) => {
      state.areas = [];
    });
  }
});

export const subcategoriasGastoPresupuestoActions = {
  ...subcategoriasGastoPresupuestoSlice.actions,
  fetchSubcategoriasGastoPresupuesto,
  fetchAreasGastoPresupuesto
};

type CategoriaGastoConSubcategoria = CategoriaGastoPresupuesto & {
  subcategorias: SubcategoriaGastoPresupuesto[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasGastoPresupuestoConSubcategorias = createSelector(
  (state: RootState) => state.subcategoriaGastosPresupuesto.entities,
  (subcategoriasGasto) => {
    const categories = uniqBy(
      subcategoriasGasto.map(
        (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaGastoConSubcategoria)
      ),
      'id'
    );

    categories.forEach((category) => {
      category.subcategorias = subcategoriasGasto.filter((sc) => sc.categoria?.id === category?.id);
    });

    const orderedCategories = orderBy(
      orderBy(categories, ['orden'], ['asc']),
      ['descripcion'],
      ['asc']
    );
    return orderedCategories.filter((a) => a) as CategoriaGastoConSubcategoria[];
  }
);

export default subcategoriasGastoPresupuestoSlice.reducer;
