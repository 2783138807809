import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';

// Services
import CategoriasIngresosService from '../../../../services/presupuesto/parametros/categoriasIngresosPresupuesto.service';

// Interfaces
import type { RootState } from '../../../store';
import type {
  CategoriaIngresoPresupuesto,
  SubcategoriaIngresoPresupuesto
} from '../../../../interfaces/presupuesto/parametros/categoriasIngresosPresupuesto';

type Entity = Awaited<
  ReturnType<typeof CategoriasIngresosService.getSubcategoriasIngresosPresupuesto>
>[number];

export const fetchsubcategoriaIngresoPresupuesto = createAsyncThunk<Entity[]>(
  'subcategoriasIngresoPresupuesto/fetchsubcategoriaIngresoPresupuestoStatus',
  (_params, api) => {
    const { subcategoriaIngresosPresupuesto } = api.getState() as RootState;
    if (subcategoriaIngresosPresupuesto.entities.length)
      return Promise.resolve(subcategoriaIngresosPresupuesto.entities);
    return CategoriasIngresosService.getSubcategoriasIngresosPresupuesto();
  }
);

interface subcategoriaIngresoPresupuestoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as subcategoriaIngresoPresupuestoState;

const subcategoriaIngresosPresupuestoSlice = createSlice({
  name: 'subcategoriaIngresosPresupuesto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchsubcategoriaIngresoPresupuesto.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchsubcategoriaIngresoPresupuesto.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchsubcategoriaIngresoPresupuesto.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const subcategoriaIngresosPresupuestoActions = {
  ...subcategoriaIngresosPresupuestoSlice.actions,
  fetchsubcategoriaIngresoPresupuesto
};

type CategoriaIngresoConSubcategoria = CategoriaIngresoPresupuesto & {
  subcategorias: SubcategoriaIngresoPresupuesto[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasIngresoPresupuestoConSubcategorias = (state: RootState) => {
  const {
    subcategoriaIngresosPresupuesto: { entities: subcategoriasIngresoPresupuesto }
  } = state;

  const categories = uniqBy(
    subcategoriasIngresoPresupuesto.map(
      (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaIngresoConSubcategoria)
    ),
    'id'
  );

  categories.forEach((category) => {
    category.subcategorias = subcategoriasIngresoPresupuesto.filter(
      (sc) => sc.categoria?.id === category?.id
    );
  });

  const orderedCategories = orderBy(
    orderBy(categories, ['orden'], ['asc']),
    ['descripcion'],
    ['asc']
  );

  return orderedCategories.filter((a) => a) as CategoriaIngresoConSubcategoria[];
};

export default subcategoriaIngresosPresupuestoSlice.reducer;
